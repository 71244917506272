import React from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import Module from '@atoms/Module/Module';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import IconText from '../IconText/IconText';
import TitleGroup from '@atoms/TitleGroup/TitleGroup';
import Button from '@atoms/Button/Button';
import './RequirementToJoin.scss';
import { useTranslation } from 'react-i18next';
import { getOnboardingLink } from '@helpers/linkHelpers';
import useLocale from '@hooks/useLocale';
import useAnalytics from '../../hooks/useAnalytics';
import Img, { FluidObject } from "gatsby-image"

interface Props {
    customTitle?: string;
    imageForTherapist: FluidObject;
    imageForCoach: FluidObject;
}

const RequirementToJoin = ({ customTitle, imageForCoach, imageForTherapist }: Props) => {
    const { t } = useTranslation();
    const { currentLanguage } = useLocale();
    const { logFirebaseEvent } = useAnalytics();

    const onOnboardingLinkClicked = () => {
        // Only for AdWords
        typeof window !== 'undefined' &&
            window.gtag('event', 'lead_therapist', {
                send_to: 'AW-482685496/MLw8CMKUhOwCELjklOYB',
            });
        logFirebaseEvent('sign_up_google_form_button_clicked');
    };

    return (
        <div id="provider-signup">
            <Section container="short" spacingTop="m" spacingBottom="xs">
                <TitleGroup
                    title={t(
                        customTitle
                            ? customTitle
                            : 'for.providers.requirements.to.join.section.title'
                    )}
                    subtitle={t(
                        'for.providers.requirements.to.join.section.subtitle'
                    )}
                    align="desktopCenter"
                />
            </Section>
            <div className="RequirementToJoin">
                <div className="RequirementToJoin-leftContent">
                    <Module radius="giant" padding="l">
                        <Section spacingBottom="s">
                            <div className="RequirementToJoin--img">
                                <Img fluid={imageForCoach} />
                            </div>
                            <Title size="l" align="center">
                                {t(
                                    'for.providers.requirements.to.join.coaching.title'
                                )}
                            </Title>
                            <Text size="s" align="center">
                                {t(
                                    'for.providers.requirements.to.join.coaching.text'
                                )}
                            </Text>
                        </Section>

                        <div className="RequirementToJoin--list">
                            <div className="RequirementToJoin--inner">
                                {[
                                    {
                                        text: t(
                                            'for.providers.requirements.to.join.coaching.point.one'
                                        ),
                                        icon: <AiOutlineCheck />,
                                    },
                                    {
                                        text: t(
                                            'for.providers.requirements.to.join.coaching.point.two'
                                        ),
                                        icon: <AiOutlineCheck />,
                                    },
                                    {
                                        text: t(
                                            'for.providers.requirements.to.join.coaching.point.six'
                                        ),
                                        icon: <AiOutlineCheck />,
                                    },
                                    {
                                        text: t(
                                            'for.providers.requirements.to.join.coaching.point.three'
                                        ),
                                        icon: <AiOutlineCheck />,
                                    },
                                    {
                                        text: t(
                                            'for.providers.requirements.to.join.coaching.point.four'
                                        ),
                                        icon: <AiOutlineCheck />,
                                    },
                                    {
                                        text: t(
                                            'for.providers.requirements.to.join.coaching.point.five'
                                        ),
                                        icon: <AiOutlineCheck />,
                                    },
                                ].map((item: any) => (
                                    <IconText
                                        key={item.text}
                                        icon={
                                            <Title
                                                size="ml"
                                                tag="span"
                                                theme="light"
                                            >
                                                {item.icon}
                                            </Title>
                                        }
                                        text={
                                            <Title size="s" noMargin tag="span">
                                                {item.text}
                                            </Title>
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                        <Section spacingTop="s">
                            <Button
                                externalLink={getOnboardingLink(
                                    currentLanguage
                                )}
                                size="m"
                                iconArrow
                                label={t(
                                    'for.providers.requirements.to.join.coaching.cta'
                                )}
                                align="center"
                                onClick={onOnboardingLinkClicked}
                            />
                        </Section>
                    </Module>
                </div>

                <div className="RequirementToJoin-rightContent">
                    <Module radius="giant" padding="l">
                        <Section spacingBottom="s">
                            <div className="RequirementToJoin--img">
                                <Img fluid={imageForTherapist} />
                            </div>
                            <Title size="l" align="center">
                                {t(
                                    'for.providers.requirements.to.join.therapy.title'
                                )}
                            </Title>
                            <Text size="s" align="center">
                                {t(
                                    'for.providers.requirements.to.join.therapy.text'
                                )}
                            </Text>
                        </Section>

                        <div className="RequirementToJoin--list">
                            <div className="RequirementToJoin--inner">
                                {[
                                    {
                                        text: t(
                                            'for.providers.requirements.to.join.therapy.point.one'
                                        ),
                                        icon: <AiOutlineCheck />,
                                    },
                                    {
                                        text: t(
                                            'for.providers.requirements.to.join.therapy.point.two'
                                        ),
                                        icon: <AiOutlineCheck />,
                                    },
                                    {
                                        text: t(
                                            'for.providers.requirements.to.join.therapy.point.three'
                                        ),
                                        icon: <AiOutlineCheck />,
                                    },
                                    {
                                        text: t(
                                            'for.providers.requirements.to.join.therapy.point.four'
                                        ),
                                        icon: <AiOutlineCheck />,
                                    },
                                    {
                                        text: t(
                                            'for.providers.requirements.to.join.therapy.point.five'
                                        ),
                                        icon: <AiOutlineCheck />,
                                    },
                                ].map((item: any) => (
                                    <IconText
                                        key={item.text}
                                        icon={
                                            <Title
                                                size="ml"
                                                tag="span"
                                                theme="light"
                                            >
                                                {item.icon}
                                            </Title>
                                        }
                                        text={
                                            <Title size="s" noMargin tag="span">
                                                {item.text}
                                            </Title>
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                        <Section spacingTop="s">
                            <Button
                                externalLink={getOnboardingLink(
                                    currentLanguage
                                )}
                                size="m"
                                iconArrow
                                label={t(
                                    'for.providers.requirements.to.join.therapy.cta'
                                )}
                                align="center"
                                onClick={onOnboardingLinkClicked}
                            />
                        </Section>
                    </Module>
                </div>
            </div>
        </div>
    );
};

export default RequirementToJoin;
