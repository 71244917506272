import React, { useEffect } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Footer from '@components/Footer/Footer';
import RequirementToJoin from '@components/RequirementToJoin';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Layout from '@components/layout';
import Seo from '@components/seo';
import Button from '@atoms/Button/Button';
import Section from '@atoms/Section/Section';
import HeaderMinimal from '@components/HeaderMinimal/HeaderMinimal';
import { CmsMappedComponent } from '@components/CMSComponents';
import './SelfEmployment.scss';
import ViewContentCTA from './ViewContentCTA';
import LanguageNotSupported from '@components/LanguageNotSupported';
import {
    usePageConfiguration,
    PageConfigurationName,
} from '@hooks/usePageConfiguration';
import { correctUrlByPageConfiguration } from '@helpers/GlobalHelpers';
import { withLocale } from '@hocs';

export const query = graphql`
    query($language: String!, $locale: AEPSYCMS_I18NLocaleCode) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        headerMobileImage: file(
            relativePath: {
                eq: "self-employment/header-guide-to-self-employment-mobile.jpg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headerDesktopImage: file(
            relativePath: {
                eq: "self-employment/header-guide-to-self-employment-desktop.jpg"
            }
        ) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        aepsyCms {
            selfEmployment(locale: $locale) {
                data {
                    id
                    attributes {
                        entries {
                            ... on AEPSYCMS_ComponentCommonZsrNumberCallout {
                                id
                                index
                                subtitle
                                title
                                calloutContent: content
                                __typename
                            }
                            ... on AEPSYCMS_ComponentCommonOrderedCard {
                                id
                                index
                                highlightedText
                                cardTitle: title
                                subtitle
                                content
                                badges {
                                    label
                                    variant
                                    id
                                }
                                images {
                                    media
                                    id
                                    src {
                                        data {
                                            attributes {
                                                url
                                                urlSharp {
                                                    childImageSharp {
                                                        fluid(maxWidth: 250, toFormat: WEBP, fit: CONTAIN) {
                                                            ...GatsbyImageSharpFluid
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                __typename
                            }
                        }
                    }
                }
            }
        }
    }
`;

const SelfEmploymentPage = ({ data }) => {
    const { t } = useTranslation();
    const pageConfiguration = usePageConfiguration({
        name: PageConfigurationName.selfEmployment,
    });

    useEffect(() => {
        if (pageConfiguration) {
            correctUrlByPageConfiguration(pageConfiguration);
        }
    }, [pageConfiguration]);

    // Header mobile
    const static_images_sources = [
        data.headerMobileImage.childImageSharp.fluid,
        {
            ...data.headerDesktopImage.childImageSharp.fluid,
            media: `(min-width: 750px)`,
        },
    ];

    const renderEntryByType = (data: any) => {
        switch (data.__typename) {
            case 'AEPSYCMS_ComponentCommonOrderedCard':
                return (
                    <CmsMappedComponent data={data}>
                        <Section spacingTop="xs">
                            <ViewContentCTA
                                content={data.content}
                                title={data.cardTitle}
                            />
                        </Section>
                    </CmsMappedComponent>
                );
            case 'AEPSYCMS_ComponentCommonZsrNumberCallout':
                return <CmsMappedComponent data={data} />;
            default:
                return <></>;
        }
    };

    const renderContent = () => {
        const {
            aepsyCms: {
                selfEmployment: { data: selfEmploymentData },
            },
        } = data;
        if (!selfEmploymentData) return <LanguageNotSupported />;
        const {
            attributes: { entries },
        } = selfEmploymentData;

        const sortedData = entries.sort((a: any, b: any) => a.index - b.index);

        return (
            <div className="SelfEmployment--blocks">
                {sortedData.map((item: any) => (
                    <Section spacingBottom="s" key={item.id}>
                        <div
                            id={`SelfEmployment_entry${Number(
                                item.highlightedText
                            )}`}
                        >
                            {renderEntryByType(item)}
                        </div>
                    </Section>
                ))}
            </div>
        );
    };

    return (
        <Layout>
            <Seo
                title={t('self.employment.seo.title')}
                description={t('self.employment.seo.description')}
            />
            <div className="global_theme-green SelfEmployment">
                <HeaderNav theme="white" signUpAsProviderShown />
                <Section theme="light">
                    <HeaderMinimal
                        theme="light"
                        title={t('self.employment.header.title')}
                        subtitle={t('self.employment.header.subtitle')}
                        btnPrimary={
                            <AnchorLink
                                offset={() => 40}
                                href="#SelfEmployment_entry1"
                            >
                                <Button
                                    variant="primary"
                                    theme="white"
                                    label={t('button.for.psychotherapist')}
                                    size="m"
                                />
                            </AnchorLink>
                        }
                        btnSecondary={
                            <AnchorLink
                                offset={() => 40}
                                href="#SelfEmployment_entry2"
                            >
                                <Button
                                    variant="primary"
                                    theme="white"
                                    label={t('button.for.psychologist')}
                                    size="m"
                                />
                            </AnchorLink>
                        }
                        imageSrc={static_images_sources}
                    />
                </Section>

                <div className="">
                    <Section container="short" spacingBottom="m" theme="light">
                        {renderContent()}
                    </Section>
                </div>

                <Section spacingBottom="l" container="large">
                    <RequirementToJoin />
                </Section>
                <Footer />
            </div>
        </Layout>
    );
};

export default withLocale(SelfEmploymentPage);
