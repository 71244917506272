import Button from '@atoms/Button/Button';
import Section from '@atoms/Section/Section';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '@atoms/Text/Text';
import { ModalContext } from '@context/modal';
import MarkdownText from '@atoms/MarkdownText/MarkdownText';

const ViewContentCTA: React.FC<any> = ({ title, content }) => {
    const { showModal } = useContext(ModalContext);
    const { t } = useTranslation();
    return (
        <Button
            variant="outlined"
            theme="primary"
            label={t('self.employment.main.cta')}
            isMobileFullsize
            onClick={() => {
                showModal(
                    <Section spacingTop="xs">
                        <Text>
                            <MarkdownText content={content} />
                        </Text>
                    </Section>,
                    {
                        title,
                    }
                );
            }}
        />
    );
};

export default ViewContentCTA;
